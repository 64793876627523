<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <form class="form_default">
      <div class="form-group">
        <label>Référence de l'avenant</label>
        <input
          type="text"
          name="libelle"
          v-model='form.ref_avenant'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
          autocomplete="off">
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Nature de l'avenant</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.nature_avenant">
          <option value="CHANGEMENT_DOMICILIATION_BANCAIRE">Changement de domiciliation bancaire</option>
          <option value="ORDRE_FINANCIER">Ordre financier</option>
          <option value="TRAVAUX_SUPPLEMENTAIRES">Travaux supplémentaires</option>
          <option value="AUTRE">Autres...</option>
        </select>
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-row row">
        <div class="form-group">
          <label>Référence de l'avenant (Fichier)</label>
          <div class="custom-file">
            <label class="custom-file-label">
              {{ inputFiles.ref_avenant_fichier }}
            </label>
            <input
              type="file"
              name="ref_avenant_fichier"
              id="ref_avenant_fichier"
              ref="ref_avenant_fichier"
              class="custom-file-input"
              placeholder="jpg jpeg png pdf xlsx csv doc"
              accept=".jpg,.jpeg,.png,.pdf,.xlsx"
              v-on:click="removeSPan('ref_avenant_fichier')"
              v-on:change="handleFileUpload('ref_avenant_fichier')"
            />
            <span class="listExtension">
              Format :
              <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
            </span>
            <span v-if="form.errors().has('ref_avenant_fichier') && affiche.ref_avenant_fichier !==   'ref_avenant_fichier'"
                  v-text="form.errors().get('ref_avenant_fichier')"
                  class="errorMsg"> </span>
          </div>
        </div>
      </div>
      <div class="form-group"
           v-if="form.nature_avenant != 'CHANGEMENT_DOMICILIATION_BANCAIRE'">
        <label>Montant de l'avenant</label>
        <input
          type="text"
          name="libelle"
          v-model='montant_avenant'
          @input="addSpace('montant_avenant')"
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
          autocomplete="off">
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group"
           v-else>
        <label>Description du changement de domiciliation</label>
        <textarea class="form-control" 
                  name="" 
                  id="" 
                  rows="3"
                  v-model="form.description_domiciliation"></textarea>
      </div>
      <div class="form-group">
        <label  >Date de signature de l'attributaire</label>
        <input
          type="date"
          name="libelle"
          v-model='form.date_signature_attributaire'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Date de signature de l'autorité contractante</label>
        <input
          type="date"
          name="libelle"
          v-model='form.date_signature_autorite_contractante'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Date d'approbation</label>
        <input
          type="date"
          name="libelle"
          v-model='form.date_approbation'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
.form_default input[type="text"],
.form_default input[type="date"],
.form_default input[type="number"],
.form_default input[type="file"],
.form_default textarea,
.form-group label,
.form_default select,
.listExtension,
.form_default button{
  font-size: 0.6em;
}
.form_default input{
  height: fit-content;
}
</style>
<script>
import form from 'vuejs-form'
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"

import {mapActions,mapGetters} from "vuex"
export default {
  name: "CreateAvenant",
  components:{
    Notif
  },
  props:["id_marche"],
  data: () => ({
    inputFiles:{
      ref_avenant_fichier:""
    },
    affiche:{
      ref_avenant_fichier:""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    listExtensionFiles:"",
    uploadfile:"",
    montant_avenant:"",
    form: form({
      id_marche:"",
      ref_avenant: "",
      ref_avenant_fichier:"",
      nature_avenant: "",
      montant_avenant: "",   
      description: "",   
      description_domiciliation: "",   
      date_signature_attributaire: "",
      date_signature_autorite_contractante: "", 
      date_approbation: "",
    })
      .rules({
        id_marche: "required",
        // ref_avenant: "required",
        // ref_avenant_fichier:"required",
        // nature_avenant: "required",
        // montant_avenant: "required",   
        // date_signature_attributaire: "required",
        // date_signature_autorite_contractante: "required", 
        // date_approbation: "required",
      })
      .messages({
        'libelle.libelle': 'Champ requis!',
        'id.id': 'Champ requis!'
      })
  }),
  watch: {
    // ['form.data']: {
    //   deep: true,
    //   immediate: false,
    //   handler: 'onFormChange'
    // },
    msgSuccessAvenant(){
      if (this.msgSuccessAvenant) {
        this.$emit("avenant",{added:true})
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loadrarc = false
        this.$refs["ref_avenant_fichier"].disabled = false
      }
    },
  },
  created(){
    this.listExtensionFiles = utils.formaFichierAccepte
  },
  computed: {
    ...mapGetters(['msgFailAvenant','msgSuccessAvenant',"files"])
  },
  methods: {
    ...mapActions(["addAvenant","saveFile"]),
    // onFormChange() {
    //   this.form.validate()
    // },
    submit() {
      console.log(this.form.data)
      this.form.id_marche=this.id_marche
      if (!this.form.validate().errors().any()) {
        this.addAvenant(this.form.data)
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (index==0) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " " && splinter[index]!='-') {
              splinter[index] = ""
            }
          }else{
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
          }
          
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions() {
      return utils.formaFichierAccepteToString()
    },
    handleFileUpload(filename) {
      // initialisation de la variable de vérification du nom
      var oldName = this.inputFiles[filename]
      if (this.$refs[filename].files[0]) {
        //condition d'existance d'un nouveau fichier
        if (this.$refs[filename].files[0].name != oldName) {
          // vérification de la concordance des noms
          this.loadrarc = true
        }
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.$refs[filename].disabled = false
            this.inputFiles[filename] = ""

            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loadrarc = false

          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
        
      }
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
  }
}
</script>
