<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <router-link v-if="user[1] != 'DGIR'"
                   to="/Marche"> Marché ></router-link>
      <router-link v-else
                   to="/Marche"> Lettre de commande</router-link>
      <router-link :to="`/Marche/detail/${this.$route.params.id}`">
        Marché n°{{$route.params.id}}
      </router-link>
      <span>> Liste des avenants</span>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des avenants
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <button class="btn btn-blue pl-5 pr-5 op-btn"
              v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
              data-toggle="modal"
              ref="modal_button"
              data-target="#exampleModal"
              @click="av='avenant'">Ajouter un avenant</button>
    </div>
    <div class="inner_container">
      <!--Tableau avenant  -->
      <div class="row mt-3">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th class="th-blue">Références</th>
              <th class="th-blue">Nature de l'avenant</th>
              <th class="th-blue">Date de signature</th>
              <th class="th-blue">Date d'approbation</th>
              <th class="th-blue">Montant</th>
              <th class="th-blue text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="long==true && longueur!=0">
            <tr v-for="(avenant,akey) in listAvenants.donnees"
                :key="akey">
              <td scope="row">{{avenant.ref_avenant}}</td>
              <td class="text-left">{{avenant.nature_avenant}}</td>
              <td >{{avenant.date_signature_attributaire}}</td>
              <td >{{avenant.date_approbation}}</td>
              <td class="text-right">{{avenant.montant_avenant.toLocaleString()}}</td>
              <td class="icon-blue text-right">
                <i class="flaticon-pencil"  
                   v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                   @click="updateForm(avenant),av='modifavenant'"
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"></i>
                <i class="flaticon-delete ml-4"
                   v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                   @click="av='del',id_avenant=avenant.id"
                   data-toggle="modal"
                   data-target="#exampleModal"></i>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="long==true && longueur==0">
            <tr>
              <td scope="row"
                  colspan="6"
                  class="text-center"> Aucune donnée disponible...</td>
            </tr>
          </tbody>
          <tbody v-else-if="long==false">
            <tr>
              <td scope="row"
                  colspan="6"
                  class="text-center">Chargement en cours...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='avenant'">Ajouter un avenant</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='modifavenant'">Détail avenant</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='del'">Supprimer avenant</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="modAvenant='',av=''">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="av=='avenant'">
            <CreateAvenant :id_marche="$route.params.id"
                           @avenant="added => (code = added)"/>
          </div>
          <div class="modal-body"
               v-if="av=='modifavenant'">
            <UpdateAvenant :id_marche="$route.params.id"
                           :mod_avenant="modAvenant"
                           @upAvenant="updated => (code = updated)"/>
          </div>
          <div class="modal-body"
               v-if="av=='del'">
            <p>Voulez-vous vraiment supprimer cet Avenant?</p>
          </div>
          <div class="modal-footer"
               v-if="av=='del'">
            <button type="button"
                    class="btn btn-secondary"
                    @click="destroyAvenant()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .table-striped tbody td,
  .table-striped thead th{
    font-size: 0.48em;
  }
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import CreateAvenant from "./Add.vue"
import UpdateAvenant from "./Update.vue"

export default {
  name:"Avenants",
  components:{
    Notif,
    CreateAvenant,
    UpdateAvenant
  },
  data: ()=>({
    av:"",
    modAvenant:"",
    id_avenant:"",
    code:false,
    user:"",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      id:""
    }).rules({
      id:"required"
    }),
    long:false,
    longueur:"",
  }),
  watch:{
    listAvenants(){
      if (this.listAvenants) {
        this.long=true
        this.longueur=this.listAvenants.donnees.length
      }
    },
    code(){
      if (this.code) {
        if (this.av=="avenant") {
          this.notif.message = "Avenant créé avec succès."
        }else{
          if (this.av=="modifavenant") {
            this.notif.message = "Avenant modifié avec succès."
          }
        }
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.setListAvenants("")
            this.getListAvenants({id:this.$route.params.id})
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgSuccessAvenant(){
      if (this.msgSuccessAvenant) {
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        this.notif.message= "Avenant supprimé avec succès"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
            this.setMsgSuccessAvenant("")
            this.setListAvenants("")
            this.getListAvenants({id:this.$route.params.id})
          }.bind(this),
          3000
        ) 
      }
    },
    msgFailAvenant(){
      if (this.msgFailAvenant) {
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.notif.message= this.msgFailAvenant
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
            this.setMsgFailAvenant("")
            this.setListAvenants("")
            this.getListAvenants({id:this.$route.params.id})
          }.bind(this),
          3000
        ) 
      }
    },
  },
  created(){
    this.getListAvenants({id:this.$route.params.id})
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed:{
    ...mapGetters(["listAvenants","msgSuccessAvenant","msgFailAvenant"])
  },
  methods:{
    ...mapActions(["getListAvenants","deleteAvenant"]),
    ...mapMutations(["setListAvenants","setMsgSuccessAvenant","setMsgFailAvenant"]),
    updateForm(avenant){
      this.modAvenant=''
      this.modAvenant=avenant
    },
    destroyAvenant(){
      this.form.id=this.id_avenant
      if (!this.form.validate().errors().any()) {
        this.deleteAvenant(this.form.data)
      }
    }
  }
}
</script>